import React from "react";
import { Card } from "antd";
import { titleCss } from "./typography";

export default (props) => (
  <Card
    css={{
      ".ant-card-head-title": { ...titleCss, whiteSpace: "normal" },
      ".ant-card-meta-title": {
        whiteSpace: "normal",
        lineHeight: 1.1,
        marginBottom: 8,
      },
    }}
    {...props}
  />
);

export const CardMeta = Card.Meta;
export const CardGrid = Card.Grid;
