import React from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import { Button, List, Statistic } from "antd";
import { MailOutlined, RocketOutlined } from "@ant-design/icons";
import tinycolor from "tinycolor2";
import { Link as GatsbyLink } from "gatsby";
import mainHeroBg from "../images/forest.jpg";
import bgMountains from "../images/bg-mountains.png";
import onePxSun from "../images/1px-sun.png";
import gr from "../images/team/guillaume-rivals.jpg";
import ah from "../images/team/ahmad-isber.jpg";
import Container from "../components/Container";
import Card from "../components/Card";
import page from "../components/page";
import useTranslation from "../lang/useTranslation";
import { Title, Paragraph } from "../components/typography";
import { mY, pY } from "../components/spacingShorthands";
import mq from "../components/mq";
import { green } from "../theme/colors";
import { GridSection } from "../components/sections";
import HeaderMenu from "../components/HeaderMenu";
import Cta from "../components/Cta";

const MainHero = () => (
  <div
    css={{
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.1)), url(${mainHeroBg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Container size="md" css={mq(pY([0, 48]))}>
      <Title css={{ ...mY(80), fontSize: "48px !important" }} white>
        {useTranslation("heroTitle")}
      </Title>
      <div css={{ ...mY(80), fontSize: "1.5em" }}>
        <Paragraph white>
          {useTranslation("subHero1")}
          <br />
          {useTranslation("subHero2")}
        </Paragraph>
      </div>
    </Container>
  </div>
);

const TeamMember = ({ img, children, name, url }) => (
  <div css={[{ textAlign: "center" }, mq({ marginBottom: [0, -48] })]}>
    <a href={url} target="_blank" rel="noreferrer">
      <img
        src={img}
        alt={name}
        css={{ height: 160, borderRadius: 160, marginBottom: 32 }}
      />
    </a>
    <Title white level={4}>
      {name}
    </Title>
    <Paragraph white>{children}</Paragraph>
  </div>
);

const Team = (props) => {
  const ahBio = useTranslation("ah");
  const grBio = useTranslation("gr");
  return (
    <GridSection
      white
      title={useTranslation("about")}
      css={[
        {
          backgroundImage: `url(${bgMountains}), linear-gradient(0deg, rgba(253,151,110,1) 0%, rgba(253,151,110,1) 100%)`,
          backgroundPosition: "bottom right",
          backgroundRepeat: "no-repeat",
        },
        mq({ backgroundSize: ["165%", "cover"] }),
      ]}
      {...props}
    >
      <TeamMember
        name="Ahmad Isber"
        url="https://www.linkedin.com/in/ahmad-isber/"
        img={ah}
      >
        {ahBio[0]}
        <br />
        {ahBio[1]}
        <br />
        {ahBio[2]}
      </TeamMember>
      <TeamMember
        name="Guillaume Rivals"
        url="https://www.linkedin.com/in/guillaumervls/"
        img={gr}
      >
        {grBio[0]}
        <br />
        {grBio[1]}
        <br />
        {grBio[2]}
      </TeamMember>
      <TeamMember
        name={useTranslation("you")}
        url="mailto:hello@contreeb.com"
        img={onePxSun}
      >
        {useTranslation("wereHiring")}
        <br />
        <a href="mailto:hello@contreeb.com" target="_blank" rel="noreferrer">
          <Button
            icon={<RocketOutlined />}
            css={{ marginTop: 8 }}
            type="primary"
            size="large"
          >
            {useTranslation("joinTeam")}
          </Button>
        </a>
      </TeamMember>
    </GridSection>
  );
};

const Step = animated(Card);

const ThreeSteps = ({ steps, ...props }) => {
  const [ref, inView] = useInView({ threshold: 0.25, triggerOnce: 1 });
  const springs = [
    useSpring({
      opacity: inView ? 1 : 0,
      left: inView ? 0 : -75,
      from: { opacity: 0, left: -75 },
    }),
    useSpring({
      opacity: inView ? 1 : 0,
      bottom: inView ? 0 : -75,
      from: { opacity: 0, bottom: -75 },
    }),
    useSpring({
      opacity: inView ? 1 : 0,
      right: inView ? 0 : -75,
      from: { opacity: 0, right: -75 },
    }),
  ];

  return (
    <div ref={ref}>
      <GridSection {...props}>
        {steps.map(({ title: t, content }, idx) => (
          <Step title={t} style={springs[idx]} key={t}>
            {content}
          </Step>
        ))}
      </GridSection>
    </div>
  );
};

const PlanFeatures = ({ features }) => (
  <List dataSource={features} renderItem={(i) => <List.Item>{i}</List.Item>} />
);

export default page(
  () => {
    const pricingPlans = ["freePlan", "proPlan"]
      .map(useTranslation)
      .map(({ features, name }, idx) => ({
        content: (
          <>
            <Paragraph>
              <Statistic
                valueStyle={{ color: green, fontSize: 40 }}
                value={[0, 99][idx]}
                precision={2}
                title={`€ ${useTranslation("perMonth")}`}
              />
            </Paragraph>
            <PlanFeatures features={features} />
            <GatsbyLink to="/login">
              <Button
                css={{ marginTop: 8 }}
                type="primary"
                size="large"
                icon={<RocketOutlined />}
              >
                {useTranslation("getStarted")}
              </Button>
            </GatsbyLink>
          </>
        ),
        title: name,
      }))
      .concat({
        title: useTranslation("enterprisePlanName"),
        content: (
          <div>
            <Paragraph>{useTranslation("biggerNeeds")}</Paragraph>
            <a
              href="mailto:hello@contreeb.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary" size="large" icon={<MailOutlined />}>
                {useTranslation("letsTalk")}
              </Button>
            </a>
          </div>
        ),
      });

    return (
      <>
        <MainHero />
        <Cta formName="cta1" />
        <ThreeSteps
          name="mission"
          title={useTranslation("mission")}
          subtitle={useTranslation("missionDescription")}
          steps={useTranslation("missionSection")}
        />
        <ThreeSteps
          name="product"
          title={useTranslation("product")}
          subtitle={useTranslation("productDescription")}
          steps={useTranslation("productSection")}
          css={{
            backgroundColor: tinycolor(green).lighten(60).toHexString(),
          }}
        />
        <ThreeSteps
          name="pricing"
          title={useTranslation("pricing")}
          steps={pricingPlans}
        />
        <Team name="about" />
        <Cta formName="cta2" />
      </>
    );
  },
  { HeaderMenu: () => <HeaderMenu homePath="." /> }
);
